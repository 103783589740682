<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">SEARCH VOUCHER</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-layout col>
          <v-flex xs12 md2>
            <v-form ref="form" class="multi-col-validation">
              <v-select
                v-if="!alert"
                v-model="year_of"
                class="mx-2"
                :items="year_of_items"
                item-value="year"
                item-text="year"
                label="Year Of"
                required
                :rules="rules.combobox_rule"
                dense
                @change="selected_year"
                outlined
              ></v-select>
              <v-select
                v-if="!alert"
                v-model="month_of_id"
                class="mx-2"
                :items="month_of_items"
                item-value="id"
                item-text="month_of"
                label="Month Of"
                required
                :rules="rules.combobox_rule"
                dense
                @change="selected_month"
                outlined
              ></v-select>
              <v-select
                v-if="!alert"
                outlined
                class="mx-2"
                v-model="category_id"
                dense
                label="Category"
                :items="category_items"
                item-value="id"
                item-text="category"
                :rules="rules.combobox_rule"
                @change="selected_category"
              ></v-select>
              <v-select
                outlined
                v-if="!is_forbidden && !alert"
                class="mx-2"
                v-model="selection"
                dense
                label="Selection"
                :items="['Branch','Regional','Territory']"
                :rules="rules.combobox_rule"
                @change="selected_category"
              ></v-select>
              <v-select
                v-if="!alert"
                outlined
                class="mx-2"
                v-model="branch_id"
                dense
                label="Branch"
                :items="branch_items"
                item-value="id"
                item-text="branch_code"
                :rules="rules.combobox_rule"
                @change="can_search_data"
              ></v-select>
              <v-select
                v-if="!alert"
                outlined
                class="mx-2 mb-9"
                v-model="type_of_voucher"
                dense
                label="Type of Voucher"
                :items="['All','Cash Voucher','Cheque Voucher','Fund Transfer Voucher']"
                :rules="rules.combobox_rule"
                @change="can_search_data"
              ></v-select>
              <v-col cols="12" v-show="alert">
                <v-alert
                  color="warning"
                  text
                  class="mb-0"

                >
                  <div class="d-flex align-start">
                    <v-icon color="warning">
                      {{ icons.mdiAlertOutline }}
                    </v-icon>

                    <div class="ms-3">
                      <p class="text-base font-weight-medium mb-1">
                        {{alert_message}}
                      </p>
                    </div>
                  </div>
                </v-alert>
              </v-col>
              <v-select
                v-if="is_can_search&&!alert"
                class="mx-2  mt-9"
                outlined
                v-model="search_by"
                :items="['Employee', 'Affiliate', 'Others']"
                label="Search By"
                required
                dense
                @change="reseter_search()"
              ></v-select>
              <v-autocomplete
                outlined
                class="mx-2"
                v-if="(search_by === 'Employee' || search_by === 'Affiliate') && (is_can_search&&!alert)"
                v-model="search"
                :items="search_items"
                :loading="isLoading"
                @keyup.enter="searching($event.target.value)"
                hide-no-data
                item-text="name2"
                item-value="id"
                label="Credit to"
                placeholder="Press Enter to Search "
                :prepend-icon="icons.mdiAccountSearchOutline"
                @change="get_search_items_info"
              ></v-autocomplete>
              <v-autocomplete
                outlined
                class="mx-2"
                v-if="(search_by === 'Employee' || search_by === 'Affiliate') && (is_can_search&&!alert)"
                v-model="search2"
                :items="search_items2"
                :loading="isLoading2"
                @keyup.enter="searching2($event.target.value)"
                hide-no-data
                item-text="name2"
                item-value="id"
                label="Payment For"
                placeholder="Press Enter to Search "
                :prepend-icon="icons.mdiAccountSearchOutline"
                @change="get_search_items_info2"
              ></v-autocomplete>
              <v-text-field
                v-if="(search_by === 'Others') && (is_can_search&&!alert)"
                class="mx-2"
                v-model="credit_to"
                dense
                label="Credit To"
                outlined
                @keyup.enter="search_data(credit_to)"
              ></v-text-field>
              <v-text-field
                v-if="(search_by === 'Others') && (is_can_search&&!alert)"
                class="mx-2"
                v-model="payment_for"
                dense
                label="Payment For"
                outlined
                @keyup.enter="search_data(payment_for)"
              ></v-text-field>
              <v-select
                v-if="is_can_search&&!alert"
                outlined
                class="mx-2"
                v-model="particulars_id"
                dense
                label="Particulars"
                :items="particulars_items"
                item-value="particulars"
                item-text="particulars"
                @change="init_search_data(particulars_id)"
              ></v-select>
              <v-text-field
                v-if="is_can_search&&!alert"
                class="mx-2"
                v-model="description"
                dense
                label="Description"
                outlined
                @keyup.enter="search_data(description)"
              ></v-text-field>
              <v-text-field
                v-if="is_can_search&&!alert"
                class="mx-2"
                v-model="cheque_no"
                dense
                label="Cheque #"
                outlined
                @keyup.enter="search_data(cheque_no)"
              ></v-text-field>
              <v-text-field
                v-if="is_can_search&&!alert"
                class="mx-2"
                v-model="request_id"
                dense
                label="Request ID"
                outlined
                @keyup.enter="search_data(request_id)"
              ></v-text-field>
              <v-text-field
                v-if="is_can_search&&!alert"
                class="mx-2"
                v-model="voucher_no"
                dense
                label="Voucher #"
                outlined
                @keyup.enter="search_data(voucher_no)"
              ></v-text-field>
              <v-text-field
                v-if="is_can_search&&!alert"
                class="mx-2"
                v-model="amount"
                dense
                label="Amount"
                outlined
                @keyup.enter="search_data(amount)"
              ></v-text-field>
            </v-form>
          </v-flex>
          <v-flex xs12 md10 class="mx-2">
            <h2>
              Print |
              <v-icon
                v-if="!is_deleting_request && data_items.length > 0"
                color="success"
                @click="print_data()"
              >
                {{ icons.mdiPrinter }}
              </v-icon>
              <v-progress-circular
                color="info"
                indeterminate
                v-if="is_deleting_request"
              ></v-progress-circular>
              Data's | Total: P {{total_amount}}
            </h2>
            <v-data-table dense
                          :headers="headers"
                          :items="data_items"
                          height="600"
                          hide-default-footer
                          disable-pagination
            >
              <template v-slot:item.date="{ item }">
                <tr>
                  <td>
                    <v-chip>{{ item.date }}</v-chip>
                  </td>
                </tr>
              </template>
              <template v-slot:item.amount="{ item }">
                <tr>
                  <td>
                    {{ item.is_counted?formatPrice(item.amount):'Not Count: '+
                    formatPrice(item.amount)}}
                  </td>
                </tr>
              </template>
              <template v-slot:item.branch_code="{ item }">
                <tr>
                  <div v-if="item.branch_code!=''">
                    <td>
                      {{ item.branch_code}}
                    </td>
                  </div>
                  <div v-else>
                    <td>
                      {{ item.ftv_from_bank.bank_code+'|'+item.ftv_from_bank.account_no }}
                    </td>
                    <td>
                      {{ item.ftv_to_bank.bank_code+'|'+item.ftv_to_bank.account_no }}
                    </td>
                  </div>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-layout>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiAccountOutline,
    mdiAccountCashOutline,
    mdiInformationOutline,
    mdiPrinter,
    mdiGoogleCirclesCommunities, mdiCharity, mdiGift, mdiHandshakeOutline
  } from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import category from "@/store/module/data/category";

  const initialState = () => {
    return {
      alert: false,
      alert_message: '',
      total_amount: 0,
      headers: [
        // {text: 'ID', value: 'id', sortable: true},
        {text: 'Request ID', value: 'request_id', sortable: true},
        {text: 'Month Of', value: 'month_of', sortable: true},
        {text: 'Voucher#', value: 'voucher_no', sortable: true},
        {text: 'Date', value: 'date', sortable: true},
        {text: 'Cheque#', value: 'cheque_no', sortable: true},
        {text: 'Category', value: 'category', sortable: true},
        {text: 'Branch', value: 'branch_code', sortable: true},
        {text: 'Payment For', value: 'payment_for', sortable: true},
        {text: 'Credit To', value: 'credit_to', sortable: true},
        {text: 'Particulars', value: 'particulars', sortable: true},
        {text: 'Description', value: 'description', sortable: true},
        {text: 'Amount', value: 'amount', sortable: true},
        {text: 'Status', value: 'status', sortable: true},
      ],
      data_items: [],
      branch_items: [],
      category_items: [],
      month_of_items: [],
      year_of_items: [],
      particulars_items: [],
      is_can_search: false,
      isLoading: false,
      isLoading2: false,
      is_forbidden: true,
      is_deleting_request: false,
      branch_id: '',
      selection: '',
      type_of_voucher: 'All',
      category_id: '',
      month_of_id: '',
      year_of: 'All',
      request_id: '',
      cheque_no: '',
      description: '',
      particulars_id: 'Select',
      date: '',
      voucher_no: '',
      credit_to: '',
      payment_for: '',
      amount: '',

      search_by: '',
      search: '',
      search_items: [],
      search2: '',
      search_items2: [],
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiPrinter,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['employee_id', 'month_of']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', [
        'employee_id',
        'month_of',
        'address',
        'contact',
        'name',
        'department',
        'position',
        'employee_category_id',
        'employee_branch_data',
        'employee_branch_id',
      ]),
      ...mapGetters('system_data', [
        'snackbar',
        'snackbar_color',
        'snackbar_text',
        'company_logo',
        'damayan',
        'chapel',
        'factory',
        'coop',
        'printing',
        'accounting',
        'admin',
        'amd',
        'audit',
        'book',
        'creative',
        'ecut',
        'hr',
        'it',
        'prot',
        'trea',
      ]),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('monthly_liquidation', ['initialize_search_voucher', 'search_voucher']),
      ...mapActions('branch_information', ['branch_data']),
      ...mapActions('request_particulars', ['request_particulars_data']),
      ...mapActions('employee', ['search_all_active_employee']),
      ...mapActions('affiliatee', ['search_all_active_affiliate']),
      initialize_data() {
        this.initialize_search_voucher()
          .then(response => {
            this.year_of_items = response.data[0].year_and_month
            this.category_items = response.data[0].category
            this.year_of_items.splice(0, 0, {
              id: -1,
              year: 'All',
            })
            this.category_items.splice(0, 0, {
              id: -1,
              category: 'All',
            })
            this.category_id = -1
            this.selected_year('All')
            this.selected_category()
          })
          .catch(error => {
            console.log(error)
          })
      },
      selected_year(value) {
        if (value != 'All') {
          var index = this.year_of_items.map(function (x) {
            return x.year;
          }).indexOf(value)
          if (index != -1) {
            this.month_of_items = this.year_of_items[index].months_data
          }
        }
        this.month_of_items.splice(0, 0, {
          id: -1,
          month_of: 'All',
        })
        this.month_of_id = -1
        this.can_search_data()
      },
      selected_month() {
        this.can_search_data()
      },
      searching(value) {
        this.credit_to = ''
        this.isLoading = true
        this.search_items = []

        if (this.search_by === 'Employee') {
          this.search_all_active_employee({
            search_word: value,
          }).then(response => {
            this.search_items = response.data
            this.credit_to = ''
            this.isLoading = false
          })
        } else if (this.search_by === 'Affiliate') {
          this.search_all_active_affiliate({
            search_word: value,
            particulars_id: this.is_ftv === 1 ? 266 : this.particulars_id,
            branch_id: this.is_ftv === 1 ? '' : this.branch_id,
          }).then(response => {
            this.isLoading = false
            this.search_items = response.data
            this.credit_to = ''
          })
        }
      },
      async get_search_items_info(value) {
        if (this.search_items.length > 0) {
          var index = this.search_items
            .map(function (x) {
              return x.id
            })
            .indexOf(value)
          if (index != -1) {
            this.credit_to = await this.search_items[index].name2
            await this.search_data(this.credit_to)
          } else {
            this.credit_to = ''
            this.search = ''
            this.search_items = []
          }
        } else {
          this.search_items = []
          this.search = ''
          this.credit_to = ''
        }
      },
      searching2(value) {
        this.payment_for = ''
        this.isLoading2 = true
        this.search_items2 = []

        if (this.search_by === 'Employee') {
          this.search_all_active_employee({
            search_word: value,
          }).then(response => {
            this.search_items2 = response.data
            this.payment_for = ''
            this.isLoading2 = false
          })
        } else if (this.search_by === 'Affiliate') {
          this.search_all_active_affiliate({
            search_word: value,
            particulars_id: this.is_ftv === 1 ? 266 : this.particulars_id,
            branch_id: this.is_ftv === 1 ? '' : this.branch_id,
          }).then(response => {
            this.isLoading2 = false
            this.search_items2 = response.data
            this.payment_for = ''
          })
        }
      },
      async get_search_items_info2(value) {
        if (this.search_items2.length > 0) {
          var index = this.search_items2
            .map(function (x) {
              return x.id
            })
            .indexOf(value)
          if (index != -1) {
            this.payment_for = await this.search_items2[index].name2
            await this.search_data(this.payment_for)
          } else {
            this.payment_for = ''
            this.search2 = ''
            this.search_items2 = []
          }
        } else {
          this.search_items2 = []
          this.search2 = ''
          this.payment_for = ''
        }
      },
      async selected_category() {
        this.is_forbidden = true
        if (this.category_id === 4 || this.category_id === 3) {
          this.is_forbidden = false
        } else {
          this.selection = ''
        }
        this.branch_id = -1
        await this.branch_data({
          category_id: this.category_id,
          is_region: this.selection === 'Regional' ? 1 : 0,
          is_territory: this.selection === 'Territory' ? 1 : 0,
        })
          .then(response => {
            this.branch_items = response.data[0].active
            this.branch_items.splice(0, 0, {
              id: -1,
              branch_code: 'All'
            })
          })
          .catch(error => {
            console.log(error)
          })
        this.particulars_id = 'Select'
        await this.request_particulars_data({
          category_id: this.category_id,
        })
          .then(response => {
            this.particulars_items = response.data[0].active
            this.particulars_items.splice(0, 0, {
              particulars: 'Select'
            })
            this.particulars_id = 'Select'
          })
          .catch(error => {
            console.log(error)
          })
        await this.can_search_data()
      },
      can_search_data() {
        this.is_can_search = this.$refs.form.validate();
        if (this.is_can_search) {
          if (this.credit_to != '' || this.payment_for != '' || this.particulars_id != 'Select' || this.description != '' || this.cheque_no != '' || this.request_id != '' || this.voucher_no != '' || this.amount != '') {
            this.search_data(this.credit_to != '' ? this.credit_to :
              (this.payment_for != '' ? this.payment_for :
                (this.particulars_id != 'Select' ? this.particulars_id :
                  (this.description != '' ? this.description :
                    (this.cheque_no != '' ? this.cheque_no :
                      (this.request_id != '' ? this.request_id :
                        (this.voucher_no != '' ? this.voucher_no :
                          (this.amount != '' ? this.amount : ''))))))))
          }
        }
      },
      init_search_data(value) {
        this.data_items = []
        if (value != 'Select') {
          this.search_data(value)
        }
      },
      search_data(value) {
        this.data_items = []
        this.alert = true
        this.alert_message = 'PLEASE WAIT...'
        this.total_amount = this.formatPrice(0)
        if (value != '' && this.is_can_search) {
          this.search_voucher({
            year_of: this.year_of,
            month_of_id: this.month_of_id,
            category_id: this.category_id,
            branch_id: this.branch_id,
            type_of_voucher: this.type_of_voucher,


            credit_to: this.credit_to,
            payment_for: this.payment_for,
            particulars_id: this.particulars_id != 'Select' ? this.particulars_id : '',
            description: this.description,
            cheque_no: this.cheque_no,
            request_id: this.request_id,
            voucher_no: this.voucher_no,
            amount: this.amount,
          })
            .then(response => {
              this.data_items = response.data
              var tto = 0;
              this.data_items.forEach(function (item) {
                if (item.is_counted) {
                  tto += parseFloat(item.amount)
                }
              });
              this.total_amount = this.formatPrice(tto)
              this.alert = false
            })
            .catch(error => {
              this.alert_message = error
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
      reseter_search() {
        this.search_items = []
        this.credit_to = ''
        this.payment_for = ''
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      async print_data() {
        this.is_deleting_request = true
        var imgData = this.company_logo

        var payments_array = []
        var widths = []
        var info_array = []
        var cheque_array = {}

        var damayan_ = this.damayan
        var chapel_ = this.chapel
        var factory_ = this.factory
        var coop_ = this.coop
        var printing_ = this.printing

        widths = [20, 40, 37, 35, 40, 120, 60, 60, 30, 38]
        payments_array.push([
          {text: 'Req. ID', alignment: 'left', style: 'main_info'},
          {text: 'Voucher #', alignment: 'left', style: 'main_info'},
          {text: 'Date', alignment: 'left', style: 'main_info'},
          {text: 'Cheque#', alignment: 'left', style: 'main_info'},
          {text: 'Particulars', alignment: 'left', style: 'main_info'},
          {text: 'Description', alignment: 'left', style: 'main_info'},
          {text: 'Payment For', alignment: 'left', style: 'main_info'},
          {text: 'Credit To', alignment: 'left', style: 'main_info'},
          {text: 'Amount', alignment: 'left', style: 'main_info'},
          {text: 'Status', alignment: 'left', style: 'main_info'},
        ])

        var tott = this.total_amount
        this.data_items.forEach(function (item, index, payment) {
          payments_array.push([
            {
              text: item.request_id,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.voucher_no,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.date,
              alignment: 'left',
              style: 'tableExample2',
            },

            {
              text: item.cheque_no,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.particulars,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.description,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.payment_for,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.credit_to,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text:
                (item.pdc_status === 0 ? 'Not Count: ' : '') +
                (item.amount / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.status,
              alignment: 'left',
              style: 'tableExample2',
            },
          ])
          if (Object.is(payment.length - 1, index)) {
            // execute last item logic
            payments_array.push([
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: 'TOTAL',
                alignment: 'right',
                style: 'tableExample2',
              },
              {
                text: tott,
                alignment: 'left',
                style: 'tableExample2',
              },
            ])
          }
        })

        info_array = [
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'YEAR OF: ',
                  {
                    text: this.year_of_items[
                      this.year_of_items
                        .map(function (x) {
                          return x.year
                        })
                        .indexOf(this.year_of)
                      ].year,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'MONTH OF: ',
                  {
                    text: this.month_of_items[
                      this.month_of_items
                        .map(function (x) {
                          return x.id
                        })
                        .indexOf(this.month_of_id)
                      ].month_of,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'CATEGORY: ',
                  {
                    text: this.category_items[
                      this.category_items
                        .map(function (x) {
                          return x.id
                        })
                        .indexOf(this.category_id)
                      ].category,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'BRANCH: ',
                  {
                    text: this.branch_items[
                      this.branch_items
                        .map(function (x) {
                          return x.id
                        })
                        .indexOf(this.branch_id)
                      ].branch_code,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'OPTION: ',
                  {
                    text: this.selection,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'TYPE OF VOUCHER: ',
                  {
                    text: this.type_of_voucher,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'CREDIT TO: ',
                  {
                    text: this.credit_to,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'PAYMENT FOR: ',
                  {
                    text: this.payment_for,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'PARTICULARS: ',
                  {
                    text: this.particulars_items[
                      this.particulars_items
                        .map(function (x) {
                          return x.particulars
                        })
                        .indexOf(this.particulars_id)
                      ].particulars,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'DESCRIPTION: ',
                  {
                    text: this.description,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'CHEQUE #: ',
                  {
                    text: this.cheque_no,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'REQUEST ID: ',
                  {
                    text: this.request_id,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'VOUCHER #: ',
                  {
                    text: this.voucher_no,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'AMOUNT: ',
                  {
                    text: this.amount,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  '',
                ],
              },
            ],
          },
        ]

        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 60, height: 54, style: 'logo'},
                {
                  stack: [
                    'HILARIO GENERAL ENTERPRISE',
                    {text: this.address, style: 'subheader2'},
                    {
                      text: this.contact,
                      style: 'subheader2',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
              ],
            },
            {
              stack: [
                {
                  columns: [
                    {
                      text: 'SEARCH VOUCHER',
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                      },
                    },
                  ],
                },
              ],
            },
            {
              columns: [{text: ' '}],
            },
            {
              columns: [{text: ' '}],
            },
            {
              stack: info_array,
            },
            {
              columns: [{text: ' '}],
            },
            {
              table: {
                widths: widths,
                body: payments_array,
                style: {fontSize: 1},
              },
              layout: {
                fillColor: function (rowIndex) {
                  return rowIndex === 0 ? '#CCCCCC' : null
                },
              },
            },
            {
              stack: [
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      text: 'PROCESSED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'DEPARTMENT RECEIVER',
                      style: 'main_data_2',
                    },
                    {
                      text: 'RECEIVED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.name,
                      style: 'main_data_2',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.position,
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'VERIFIED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'AUDITED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'BOARD OF DIRECTOR',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (TREASURY)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'APPROVED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: 'Signature Over Printed Name (ACCOUNTING)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'ALBERT M. HILARIO',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'CEO',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (EXECUTIVE)',
                      style: 'main_data_2',
                    },
                  ],
                },
              ],
            },

            cheque_array,
          ],
          footer: function (currentPage, pageCount) {
            return {
              margin: 10,
              columns: [
                {
                  text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                  fontSize: 9,
                  alignment: 'left',
                },
                {
                  text: '',
                },
              ],
            }
          },
          styles: {
            tableExample: {
              fontSize: 9,
            },
            tableExample2: {
              fontSize: 7,
            },
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader: {
              fontSize: 11,
            },
            subheader2: {
              fontSize: 10,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 9,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        pdfMake.createPdf(docDefinition).open()
        this.is_deleting_request = false
      },
    },
  }
</script>
